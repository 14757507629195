import React from 'react';
// Modules
import {ListItem, ListHeader, ListBottom} from '../../molecules'
import {AddListItem} from '../../atoms'
import AddScreen from '../AddScreen'
// Styles
import './expenseList.sass'
// Data-Management
import {data} from '../../../data/dummy'
import {graphql, compose} from 'react-apollo'
import {getSort, getFilters} from '../../../utils/apollo/local/actions'
import {sortFunction, filterData} from '../../../utils/utils.js'

class ExpenseList extends React.Component {

  state = {
    addScreen: false,
    data: data,
    editData: undefined
  }

  endOfEntries = React.createRef()

  componentDidMount () {
    // this.scrollToBottom()
  }
  componentDidUpdate () {
    // this.scrollToBottom()
  }
  scrollToBottom = () => {
    this.endOfEntries.current.scrollIntoView({behavior: 'smooth'})
  }

  sortData = (data) => {
    return data.sort(sortFunction(this.props.sort.filters.sortBy, this.props.sort.filters.sortReverse))
  }

  filterData = () => {
    return filterData(this.props.filters.filters, this.state.data)
  }

  toggleAddScreen = () => this.setState({addScreen: !this.state.addScreen})

  updateData = (data) => {
    this.setState({data, addScreen: false})
  }

  handleEdit = (item) => {
    this.setState({editData: item, addScreen: true})
  }

  render() {
    console.log('this.props (ExpenseList)', this.props);
    const filteredData = this.filterData()
    const dataList = this.sortData(filteredData)
    const {addScreen} = this.state
    return(
      <React.Fragment>
        <ul className="el__container">
          <ListHeader />
          <div className="el__content">
            {dataList.map(item => {
              return <ListItem user={item.user} amount={item.amount} currency={item.currency} category = {item.category} description = {item.description} date={item.date} key={item.id} handleEdit={this.handleEdit}/>
            })}
            <AddListItem clickHandler={this.toggleAddScreen} />
          </div>
          <div ref={this.endOfEntries}></div>
          <ListBottom />
        </ul>
        {addScreen && <AddScreen close={this.toggleAddScreen} data={this.state.data} item={this.state.editData} handleChange={this.updateData}/>}
      </React.Fragment>
    );
  }
};

export default compose(
  graphql(getSort, {name: "sort"}),
  graphql(getFilters, {name: "filters"})
)(ExpenseList);
