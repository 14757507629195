import React from 'react';

import FilterHeader from '../FilterHeader'
import {TwoSidedRange} from '../../atoms'
import './rangefilter.sass'

// Data-Management
import {updateFilters, getFilters} from '../../../utils/apollo/local/actions'
import {graphql, compose} from 'react-apollo'

class RangeFilter extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      maxValue: this.props.filters.filters.filteredByAmountUpper,
      minValue: this.props.filters.filters.filteredByAmountLower
    }
  }

  setValue = (minValue, maxValue) => {
    this.setState({minValue, maxValue});
    this.submitToGlobalSate()
  }

  submitToGlobalSate = () => {
    const {filters} = this.props.filters
    this.props.updateFilters({variables: {...filters, filteredByAmountLower: this.state.minValue, filteredByAmountUpper: this.state.maxValue}})
  }

  resetFilters = async () => {
    const {filters} = this.props.filters
    this.props.updateFilters({variables: {...filters, filteredByAmountLower: null, filteredByAmoutUpper: null}})
    await this.setState({maxValue: this.props.maxValue, minValue: 0});
  }

  getFilterStatus = () => {
    let status = false
    console.log('this.state.maxValue', this.state.maxValue);
    console.log('this.props.maxValue', this.props.maxValue);
    if(this.state.minValue > 0 || this.state.maxValue < this.props.maxValue){
      if(this.state.minValue !== null || this.state.maxValue !== null){
      status = true
      }
    }
    return status
  }

  render(){
    const status = this.getFilterStatus()
    return(
      <section className="rf__container">
        <FilterHeader handleReset={this.resetFilters} handleFilter={this.submitToGlobalSate} heading="Nach Betrag filtern:" color="black" active={status}/>
        <TwoSidedRange maxValue={this.props.maxValue} minValue={0} currentMin={this.state.minValue} currentMax={this.state.maxValue} setValue={this.setValue}/>
      </section>
    )
  }
}

export default compose(
  graphql(updateFilters, {name: "updateFilters"}),
  graphql(getFilters, {name: "filters"})
)(RangeFilter);
