/*** Import Libraries ***/
import React from 'react';

/*** Import CSS ***/
import './closeIcon.sass'

const CloseIcon = (props) => {
/*** CloseIcon
*
* *** Needed from parent
* @param closeElement = Func: triggers action in parent component
* @param color = String: Hex-Color
*
***/
        return (
            <div className="close__container" onClick={() => props.closeElement()}>
                <div className={props.color ? `${props.color} close__content` : 'close__content'}></div>
                <div className={`close__content ${props.color}`}></div>
            </div>
        );
}

export default CloseIcon;
