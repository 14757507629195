import React from 'react';
import {ReloadButton, CheckButton} from '../../atoms';


import './filterheader.sass'

const FilterHeader = (props) => {
  return(
    <div className="filterheader__container">
      <h1>{props.heading}</h1>
      <div className="filterheader__buttons">
        <ReloadButton action={() => props.handleReset()} title="Filter zurücksetzen"/>
        <CheckButton action={() => props.handleFilter()} color={props.color} active={props.active} title="Filter angewandt"/>
      </div>
    </div>
  )
}

export default FilterHeader
