import React from 'react';

import './buttons.sass'

const TwoStepButton = (props) => {
  return(
    <button className={props.buttonActive ? "twoStep--is-active" : "twoStep"} onClick={props.clickHandler}>
          {props.buttonActive ? props.textActive : props.textInactive}
    </button>
  )
};

export default TwoStepButton;
