import gql from 'graphql-tag';
/*** Update-Query for local store
 * Updating sort variables
 ***/
export const updateSort = gql`
  mutation updateSort($sortBy: String!, $sortReverse: Boolean!) {
    updateSort(sortBy: $sortBy, sortReverse: $sortReverse) @client {
      sortBy
      sortReverse
    }
  }
`;
