import React from 'react';

import './calendar.sass'

import calendar, {isDate, isSameDay, isSameMonth, getDateISO, getNextMonth, getPreviousMonth, WEEK_DAYS_DE, CALENDAR_MONTHS_DE} from './calendarHelper';

class Calendar extends React.Component {
  state = {...this.resolveStateFromProp(), today: new Date()}

  resolveStateFromProp(){
    return this.resolveStateFromDate(this.props.date)
  }

  resolveStateFromDate(date){
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    return {
      current: isDateObject ? date : null,
      month: +_date.getMonth()+1,
      year: _date.getFullYear()
    };
  }

  handleClickOutside = () => this.props.closeElement()

  componentDidMount() {
    const now = new Date();
    const tomorrow = new Date().setHours(0,0,0,0) + 24*60*60*1000;
    const ms = tomorrow - now;

    this.dayTimeout = setTimeout(() => {
      this.setState({today: new Date()}, this.clearDayTimeout);}, ms);
  }

  componentDidUpdate(prevProps) {
    const {date, onDateChanged} = this.props
    const {date: prevDate} = prevProps;
    const dateMatch = date === prevDate || isSameDay(date, prevDate);

    !dateMatch && this.setState(this.resolveStateFromDate(date), () => {typeof onDateChanged === "function" && onDateChanged(date)})
  }

  clearDayTimeout = () => {
    this.dayTimeout && clearTimeout(this.dayTimeout);
  }

  componentWillUmount() {
    this.clearPressureTimer();
    this.clearDayTimeout();
  }

  getCalendarDates = () => {
    const {current, month, year} = this.state;
    const calendarMonth = month || +current.getMonth()+1;
    const calendarYear = year || +current.getFullYear();

    return calendar(calendarMonth, calendarYear);
  }

  renderMonthAndYear = () => {
    const {month, year} = this.state;
    const monthName = Object.keys(CALENDAR_MONTHS_DE)[Math.max(0, Math.min(month-1, 11))];

    return (
      <div className = "calendar__header">
        <div className="calendar__arrow" onMouseDown={this.handlePrevious} onMouseUp={this.clearPressureTimer} title="Vorheriger Monat">
          <div>◀</div>
        </div>
        <div className="calendar__month">
          {monthName} {year}
        </div>
        <div className="calendar__arrow" onMouseDown={this.handleNext} onMouseUp={this.clearPressureTimer} title="Nächster Monat">
           	<div>▶</div>
        </div>
      </div>
    )
  }

  renderDayLabel = (day, index) => {
    const dayLabel = WEEK_DAYS_DE[day].toUpperCase();
    return(
      <div className="calendar__day" key={dayLabel} index ={index}>
        {dayLabel}
      </div>
    )
  }

  renderCalendarDate = (date, idx) => {
    const {current, month, year, today} = this.state;
    const _date = new Date(date.join("-"));

    const isToday = isSameDay(_date, today);
    const isCurrent = current && isSameDay(_date, current);

    const inMonth = month && year && isSameMonth(_date, new Date([year, month, 1].join("-")))

    const onClick = this.gotoDate(_date);

    const props = {index: idx, onClick, title: _date.toLocaleDateString("de-DE", {weekday: "short", year: 'numeric', month: 'long', day: 'numeric'})};

    const stylingDate = isCurrent ? "calendar__date--is-highlighted" : isToday ? "calendar__date--is-today" : "calendar__date";
    const stylingMonth = inMonth ? "" : "calendar__date--is-other"
    return (
      <div className={stylingDate + " " + stylingMonth} key={getDateISO(_date)} {...props}>
        {_date.getDate()}
      </div>
    );
  }

  gotoDate = date => e => {
    e && e.preventDefault();
    const {current} = this.state;
    const {onDateChanged} = this.props;

    !(current && isSameDay(date, current)) && this.setState(this.resolveStateFromDate(date), ()=>{
      typeof onDateChanged === "function" && onDateChanged(date)
    });
  }

  gotoPreviousMonth = () => {
    const {month, year} = this.state;
    const prev = getPreviousMonth(month, year)
    this.setState({month: prev.month, year: prev.year});
  }

  gotoNextMonth = () => {
    const {month, year} = this.state;
    const next = getNextMonth(month, year)
    this.setState({month: next.month, year: next.year});
  }

  gotoPreviousYear = () =>{
    const {year} = this.state;
    this.setState({year: year-1})
  }

  gotoNextYear = () =>{
    const {year} = this.state;
    this.setState({year: year+1})
  }

  handlePressure = fn => {
    if (typeof fn === "function"){
      fn();
      this.pressureTimeout = setTimeout(() => {this.pressureTimer = setInterval(fn, 100);},500
      )
    }
  }

  clearPressureTimer = () => {
    this.pressureTimer && clearInterval(this.pressureTimer)
    this.pressureTimeout && clearInterval(this.pressureTimeout)
  }

  handlePrevious = e => {
    e && e.preventDefault();
    const fn = e.shiftKey ? this.gotoPreviousYear : this.gotoPreviousMonth;
    this.handlePressure(fn);
  }

  handleNext = e => {
    e && e.preventDefault();
    const fn = e.shiftKey ? this.gotoNextYear : this.gotoNextMonth;
    this.handlePressure(fn);
  }

  render(){
    return(
      <div className="calendar__container">
        {this.renderMonthAndYear()}
        <div className = "calendar__grid">
          <React.Fragment>
            {Object.keys(WEEK_DAYS_DE).map(this.renderDayLabel)}
          </React.Fragment>
          <React.Fragment>
            {this.getCalendarDates().map(this.renderCalendarDate)}
          </React.Fragment>
        </div>
      </div>
    )
  }
}

export default Calendar;
