import React from 'react';

import DatePicker from '../DatePicker';
import FilterHeader from '../FilterHeader';

import {updateFilters, getFilters} from '../../../utils/apollo/local/actions'
import {graphql, compose} from 'react-apollo'

import './datefilter.sass'

class DateFilter extends React.Component {

  state = {
    dates: [this.props.filters.filters.filteredByDateLower, this.props.filters.filters.filteredByDateUpper],
    error: [false, false]
  }

  handleDateChange = async (date, idx) => {
    const {dates, error} = this.state
    dates[idx] = date
    error[idx] = false
    if(idx === 0){
      if(date > dates[1]){
        error[idx] = "Datum muss vor dem Enddatum liegen."
      }
    }else{
      if(date < dates[0]){
        error[idx] = "Datum muss nach dem Startdatum liegen."
      }
    }
    await this.setState({dates, error});
    this.submitToGlobalSate()
  }

  submitToGlobalSate = () => {
    const {filters} = this.props.filters
    const {dates} = this.state;
    const filteredByDateLower = dates[0]
    const filteredByDateUpper = dates[1]
    this.props.updateFilters({variables: {...filters, filteredByDateLower, filteredByDateUpper}})
  }

  resetFilters = () => {
    const {filters} = this.props.filters
    const filteredByDateLower = null
    const filteredByDateUpper = null
    const dates = [undefined, undefined]
    const error = [false, false]
    this.setState({dates, error})
    this.props.updateFilters({variables: {...filters, filteredByDateLower, filteredByDateUpper}})
  }

  getFilterStatus = () => {
    let status = false
    const {dates} = this.state
    dates.forEach(item => {
      if(item !== null && item !== undefined){
        status = true
      }
    })
    return status
  }

  render(){
    const status = this.getFilterStatus()
    return(
      <section className="datefilter__container">
        <FilterHeader handleReset={this.resetFilters} handleFilter={this.submitToGlobalSate} heading="Nach Datum filtern:" color="black" active={status}/>
        <div className="datefilter__inner">
          <DatePicker label="Ausgaben nach" value={this.state.dates[0]} onDateChanged={(date) => this.handleDateChange(date, 0)} error={this.state.error[0]}/>
          <DatePicker label="Ausgaben vor" value={this.state.dates[1]} onDateChanged={(date) => this.handleDateChange(date, 1)} error={this.state.error[1]}/>
        </div>
      </section>
    )
  }
}

export default compose(
  graphql(updateFilters, {name: "updateFilters"}),
  graphql(getFilters, {name: "filters"})
)(DateFilter);
