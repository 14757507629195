import React from 'react';

import {BurgerIcon, MainButton} from '../../atoms'

class MenuButton extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      buttonActive: false
    }
  }

  handleClick = () => {
    this.props.clickHandler()
    this.setState({buttonActive: !this.state.buttonActive});
  }

  render(){
    return(
      <React.Fragment>
        <MainButton clickHandler={this.handleClick}>
          <BurgerIcon buttonActive={this.state.buttonActive} />
        </MainButton>
      </React.Fragment>
    );
  }
}

export default MenuButton;
