// Sort function for data (called with [...].sort(compareValues(key, order)))
export const sortFunction = function compareValues(key, order=false) {
  return function(a, b) {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      order ? (comparison * -1) : comparison
    );
  };
}

export const filterData = (filters, data) => {
  let filteredData

  if(filters.filteredByUsers.length > 0){
    filteredData = data.filter(item => filters.filteredByUsers.includes(item.user.name))
  }else{
    filteredData = data
  }
  if(filters.filteredByCategory.length > 0){
    filteredData = filteredData.filter(item => filters.filteredByCategory.includes(item.category))
  }
  if(filters.filteredByDateLower !== null){
    filteredData = filteredData.filter(item => item.date >= filters.filteredByDateLower)
  }
  if(filters.filteredByDateUpper !== null){
    filteredData = filteredData.filter(item => item.date <= filters.filteredByDateUpper)
  }
  if(filters.filteredByAmountLower !== null){
    filteredData = filteredData.filter(item => item.amount >= filters.filteredByAmountLower)
  }
  if(filters.filteredByAmountUpper !== null){
    filteredData = filteredData.filter(item => item.amount <= filters.filteredByAmountUpper)
  }
  return filteredData
}
