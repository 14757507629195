import React from 'react';

import './userselection.sass'

import {CheckBoxUser, CheckBox} from '../../atoms'
import {getUserData, getAllUsers, getAllCategories} from '../../../utils/dataManagement'

class UserSelection extends React.Component {

  state = {
    checkboxes: [],
    showInput: false,
    inputValue: "",
    checked: this.props.selectedItem
  }

  createState = () => {
    let items
    if(this.props.user){
      items = getUserData(getAllUsers(this.props.data), this.props.data)
    }else{
      items = getAllCategories(this.props.data)
    }
    const checkboxes = items.map(i => {return {item: i, checked: false}})
    this.setState({checkboxes})
  }

  componentDidMount() {
    this.createState();
  }

  handleCheckboxCheck = (index) => {
    const {checkboxes} = this.state;
    checkboxes.forEach((box, idx) => {
      if(index!==idx){
        box.checked = false
      }else{
        box.checked = true
      }
    })
    this.setState({checkboxes})
    this.passChecked()
  }

  passChecked = () => {
    const {checkboxes} = this.state;
    let selected
    checkboxes.forEach(box => {
      if(box.checked){
        selected = box
        return
      }
    })
    selected ? this.props.onInputChanged(selected.item) : this.props.onInputChanged(null)
  }

  toggleInput = () => this.setState({showInput: !this.state.showInput})

  renderCheckboxes = () => {
    const {checkboxes} = this.state
    if(this.props.user){
      return(
      checkboxes.map((box, index) => {
        return <CheckBoxUser name={box.item.name} path={box.item.img_profile} key={box.item.name} checked={box.checked} idx={index} changeHandler={this.handleCheckboxCheck} />
      }))
    }else{
      return(
        <React.Fragment>
{          checkboxes.map((box, index) => {
            return <CheckBox name={box.item} key={box.item} checked={box.checked} idx={index} changeHandler={this.handleCheckboxCheck} />
          })}
          {this.state.showInput ?
            (<div>
              <input className="selection__input" value={this.state.inputValue} onChange={this.handleInputChange} onKeyDown={this.submitInputChange} placeholder="Kategorie benennen" autoFocus/>
            </div>) :
            <CheckBox name="+" checked={false} idx={-100} changeHandler={this.toggleInput} />}
        </React.Fragment>
      )
    }
  }

  handleInputChange = (e) => {
    const inputValue = e.target.value
    this.setState({inputValue})
    this.props.onInputChanged(inputValue)
  }

  submitInputChange = (e) => {
    if(e.keyCode !== 13) return
    const {checkboxes, inputValue} = this.state
    checkboxes.forEach(box => box.checked= false)
    checkboxes.push({item: inputValue, checked: true})
    this.setState({inputValue: "", checkboxes, showInput: false})
  }

  render() {
    return (
      <div className="userselection">
        <h3>{this.props.label}</h3>
        <div className="userselection__subcontainer">
          {this.renderCheckboxes()}
        </div>
      </div>
    );
  }
}

export default UserSelection
