import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink} from 'apollo-link';
import {withClientState} from 'apollo-link-state';

/*** Imports for local Apollo-Store ***/
import defaults from './local/defaults'
import resolvers from './local/resolvers'


// const httpLink = createHttpLink({uri: "https://api.graph.cool/simple/v1/cjqwbzhvs0bi40100x0u7n5w4"})

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers
})

const link = ApolloLink.from([
  stateLink,
  // httpLink
])

const client = new ApolloClient({
  link,
  cache
})

export default client;
