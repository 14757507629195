import gql from 'graphql-tag';

export const getSort = gql`
  query filters{
    filters{
      sortBy
      sortReverse
    }
  }
`;
