import React from 'react';

import './listItem.sass'

//Data-Management
import {updateSort, getSort} from '../../../utils/apollo/local/actions'
import {graphql, compose} from 'react-apollo'

class ListHeader extends React.Component{

  handleClick = (field) => {
    let reverse = this.props.sort.filters.sortReverse
    if(field === this.props.sort.filters.sortBy){
      reverse = !reverse
    }else{
      reverse = false
    }
    this.props.updateSort({variables: {sortBy: field, sortReverse: reverse}})

  }

  getClass = (field) => {

    let className = ""
    if(field === this.props.sort.filters.sortBy){
      className = className + "li__header-bold "
      if(this.props.sort.filters.sortReverse){
        className = className + "li__header-reverse "
      }
    }
    className = className + "li__header-" + field
    return className
  }

  render(){
    return(
      <div className="li__header">
        <li className="li__header-li">
          <div className={this.getClass("date")} onClick={() => this.handleClick("date")}>
            Datum
          </div>
          <div className={this.getClass("user")} onClick={() => this.handleClick("user")}>
            User
          </div>
          <div className={this.getClass("description")} onClick={() => this.handleClick("description")}>
            Ausgabe
          </div>
          <div className={this.getClass("category")} onClick={() => this.handleClick("category")}>
            Kategorie
          </div>
          <div className={this.getClass("amount")} onClick={() => this.handleClick("amount")}>
            Betrag
          </div>
        </li>
        <div className="colouredDivider"></div>
        </div>
    )
  }
}

export default compose(
  graphql(updateSort, {name: "updateSort"}),
  graphql(getSort, {name: "sort"})
)(ListHeader);
