import {getSort} from '../actions'

export const updateSort =  (_, { sortBy, sortReverse }, { cache }) => {
    const query = getSort
    const previousState = cache.readQuery({ query });
    const data = {
      filters: {
        ...previousState.filters,
        sortBy: sortBy,
        sortReverse: sortReverse
      },
    };
    cache.writeQuery({
      query,
      data,
    });

    return null;
  }
