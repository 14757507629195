import React from 'react';

import {CheckBox, CheckBoxUser} from '../../atoms'
import FilterHeader from '../FilterHeader'
import './checkForm.sass'

// Data-Management
import {updateFilters, getFilters} from '../../../utils/apollo/local/actions'
import {graphql, compose} from 'react-apollo'

class CheckForm extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      checkboxes: [],
    }
  }

  createState = () => {
    const {filters} = this.props.filters
    const checkboxes = this.props.items.map(i => {return {item: i, checked: false}})
    if(this.props.type === "img"){
      checkboxes.forEach(box => {
        if(filters.filteredByUsers.includes(box.item.name)){
          box.checked = true
        }
      })
    }else{
      checkboxes.forEach(box => {
        if(filters.filteredByCategory.includes(box.item)){
          box.checked = true
        }
      })
    }

    this.setState({checkboxes});

  }

  componentDidMount() {
    this.createState()
  }

  submitToGlobalSate = () => {
    const {filters} = this.props.filters
    const {checkboxes} = this.state;
    let checked = []
    checkboxes.forEach(box => {
      if(box.checked){
        checked.push(this.props.type === "img" ? box.item.name : box.item)
      }
    })
    const filteredByUsers = this.props.type === "img" ? checked : filters.filteredByUsers;
    const filteredByCategory = this.props.type === "reg" ? checked : filters.filteredByCategory;
    this.props.updateFilters({variables: {...filters, filteredByUsers, filteredByCategory}})
  }

  handleCheckboxCheck = (index) => {
    const {checkboxes} = this.state;
    checkboxes[index].checked = !checkboxes[index].checked;
    this.setState({checkboxes});
    this.submitToGlobalSate()
  }

  setCheckboxes = () => {
    if(this.props.type === "img"){
      return (
        this.state.checkboxes.map((box, index) => {
          return <CheckBoxUser name={box.item.name} path={box.item.img_profile} key={box.item.name} checked={box.checked} idx={index} changeHandler={this.handleCheckboxCheck}/>
      }))
    }else{
      return(
        this.state.checkboxes.map((box, index) => {
        return <CheckBox name={box.item} key={box.item} checked={box.checked} idx={index} changeHandler={this.handleCheckboxCheck}/>
        })
      )
    }
  }

  resetFilters = () => {
    const {checkboxes} = this.state;
    const {filters} = this.props.filters
    checkboxes.forEach(box => {
      box.checked = false
    })
    this.setState({checkboxes})
    if(this.props.type === "img"){
      this.props.updateFilters({variables: {...filters, filteredByUsers: []}})
    } else {
      this.props.updateFilters({variables: {...filters, filteredByCategory: []}})
    }
  }

  getFilterStatus = () => {
    let status = false
    const {checkboxes} = this.state
    checkboxes.forEach(box => {
      if(box.checked){
        status = true
      }
    })
    return status
  }

  render(){
    const status = this.getFilterStatus()
    return(
      <section className="cf__container">
        <FilterHeader handleReset={this.resetFilters} handleFilter={this.submitToGlobalSate} heading={this.props.title} color="black" active ={status}/>

        <form className="cf__form">
          <div className="cf__boxes">
            {this.setCheckboxes()}
          </div>
        </form>
      </section>
    );
  }
}

export default compose(
  graphql(updateFilters, {name: "updateFilters"}),
  graphql(getFilters, {name: "filters"})
)(CheckForm);
