export const data = [
  {
    "amount": 69.44,
    "currency": "EUR",
    "date": "2019-09-02T09:47+02:00",
    "user": {"name": "Oliver Schwamb", "img_profile": "profile_os"},
    "category": "Lebensmittel",
    "description": "Alnatura",
    "id": 1
  },
  {
    "amount": 13.01,
    "currency": "EUR",
    "date": "2019-09-25T16:47+02:00",
    "user": {"name": "Oliver Schwamb", "img_profile": "profile_os"},
    "category": "Lebensmittel",
    "description": "Vollcorner",
    "id": 2
  },
  {
    "amount": 127.39,
    "currency": "EUR",
    "date": "2019-05-22T13:11+02:00",
    "user": {"name": "Oliver Schwamb", "img_profile": "profile_os"},
    "category": "Reisen",
    "description": "Deutsche Bahn",
    "id": 3
  },
  {
    "amount": 11.56,
    "date": "2019-08-31T16:47+02:00",
    "currency": "EUR",
    "user": {"name": "Olga Zoll", "img_profile": "profile_oz"},
    "category": "Lebensmittel",
    "description": "Alnatura",
    "id": 4
  },
  {
    "amount": 234.12,
    "currency": "EUR",
    "date": "2019-08-11T05:01+02:00",
    "user": {"name": "Olga Zoll", "img_profile": "profile_oz"},
    "category": "Haushalt",
    "description": "Seifen",
    "id": 5
  },
  {
    "amount": 74.33,
    "currency": "EUR",
    "date": "2019-08-11T05:01+02:00",
    "user": {"name": "Olga Zoll", "img_profile": "profile_oz"},
    "category": "Haushalt",
    "description": "Seifen",
    "id": 6
  },
  {
    "amount": 123.14,
    "currency": "EUR",
      "date": "2019-09-25T16:43+02:00",
    "user": {"name": "Oliver Schwamb", "img_profile": "profile_os"},
    "category": "Lebensmittel",
    "description": "Vollcorner",
    "id": 7
  },
  {
    "amount": 42.99,
    "currency": "EUR",
      "date": "2019-09-25T16:47+02:00",
    "user": {"name": "Oliver Schwamb", "img_profile": "profile_os"},
    "category": "Sonstiges",
    "description": "Neuer Fernseher",
    "id": 8
  },
]
