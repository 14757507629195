import {getFilters} from '../actions'

export const updateFilters =  (_, { filteredByUsers, filteredByCategory, filteredByDateLower, filteredByDateUpper, filteredByAmountLower, filteredByAmountUpper }, { cache }) => {

    const query = getFilters

    const previousState = cache.readQuery({ query });
    const data = {
      filters: {
        ...previousState.filters,
        filteredByUsers: filteredByUsers,
        filteredByCategory: filteredByCategory,
        filteredByDateLower: filteredByDateLower,
        filteredByDateUpper: filteredByDateUpper,
        filteredByAmountLower: filteredByAmountLower,
        filteredByAmountUpper: filteredByAmountUpper
      },
    };

    cache.writeQuery({
      query,
      data,
    });

    return null;
  }
