
// Must be exchanged for actual data-sorting logic
const uniqueFiltering = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const getAllCategories = (data) => {
  const allCategories = [...new Set(data.map(item => item.category))]
  return allCategories.filter(uniqueFiltering)
}

// Must be exchanged for actual data-sorting logic
export const getAllUsers = (data) => {
  const allUsers = [...new Set(data.map(item => item.user.name))];
  return allUsers.filter(uniqueFiltering)
}

// Must be exchanged for actual data-sorting logic
export const getUserData = (users, data) => {
  let userProfiles = []
  users.forEach((user) => {
    const userData = data.find((el) => el.user.name === user)
    userProfiles.push(userData.user)
  })
  return userProfiles
}

export const getAllTypes = (data) => {
  const allTypes = [...new Set(data.map(item => item.description))]
  return allTypes.filter(uniqueFiltering)
}
