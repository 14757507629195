import React from 'react';

import './mainButton.sass'

class MainButton extends React.Component {
  render(){
    return(
    <div className="mb__button" onClick={this.props.clickHandler}>
      <span className="mb__content">
          {this.props.children}
      </span>
    </div >
    )
  }
}

export default MainButton;
