import gql from 'graphql-tag';

export const getFilters = gql`
  query filters{
    filters{
      filteredByUsers
      filteredByCategory
      filteredByAmountLower
      filteredByAmountUpper
      filteredByDateLower
      filteredByDateUpper
    }
  }
`;
