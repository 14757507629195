import React from 'react';

import './listBottom.sass'

import UserSection from './UserSection'

import {data} from '../../../data/dummy.js'
import {filterData} from '../../../utils/utils.js'
import {getFilters} from '../../../utils/apollo/local/actions'
import {graphql} from 'react-apollo'

class ListBottom extends React.Component{

  uniqueFiltering = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  getAllUsers = () => {
    const allUsers = [...new Set(data.map(item => item.user.name))];
    return allUsers.filter(this.uniqueFiltering)
  }

  getUserData = (users) => {
    const userData = data.filter((item) => {
      return users.filter((user) => {
        return item.user.name  === user
      }).length !== 0
    })
    return userData
  }

  calculateSumByUser = (user) => {
    const userData = this.getUserData(user)
    return this.calculateSum(userData)
  }

  calculateSum = (data) => {
    let initalValue = 0
    const sum = data.reduce((accumulator, current) => accumulator + current.amount, initalValue)
    return sum;
  }

  getProfile = (username) => {
    const dataPoint = data.find((el) => {return el.user.name === username})
    return dataPoint.user
  }

  render(){
    const allUsers = this.getAllUsers()
    const filteredData = filterData(this.props.filters.filters, data)
    return(
    <div className="lb__outer">
      <div className="colouredDivider"></div>
      <div className="lb__container">
        <div className="lb__users">
          {allUsers.map(user => {
            return <UserSection name={user} sum={this.calculateSumByUser([user])} path={this.getProfile(user).img_profile} key={user}/>
          })}

        </div>
        <div className="lb__sum">
          <div className="lb__sum-selected">
            {this.calculateSum(filteredData).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </div>
          <div className="lb__sum-all">
            Gesamt: {this.calculateSum(data).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </div>
        </div>
      </div>
    </div>
    )
  }
};

export default graphql(getFilters, {name: "filters"})(ListBottom);
