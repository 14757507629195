import React from 'react';

import './addListItem.sass'

const AddListItem = (props) => {
  return(
    <button className="ali__button" onClick={props.clickHandler}>
      <div>+</div>
    </button>
  )
}

export default AddListItem
