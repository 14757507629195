import React from 'react';
import {findDOMNode} from 'react-dom';

export function withOutsideClick(WrappedComponent){
  return class OustideClickable extends React.Component{
    componentDidMount() {
      document.addEventListener('click', this.handleOutsideClick, true)
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, true)
    }

    handleOutsideClick = e => {
      if(!this.node || (!this.node.contains(e.target) && typeof this.wrappedComponent.handleClickOutside === 'function')){
        this.wrappedComponent.handleClickOutside(e)
      }
    }

    render(){
      return(
        <WrappedComponent {...this.props}
          ref={c => {
            this.wrappedComponent = c
            this.node = findDOMNode(c)
          }} />
      )
    }
  }
}
