import React from 'react';

import './profilePicture.sass'

const ProfilePicture = (props) => {
  return(
    <img className="pp__avatar-img" src={require("../../../img/" + props.path + ".jpg")} alt={props.name}/>
  );
};

export default ProfilePicture;
