/*** Import Libraries ***/
import React from 'react';

/*** Import CSS ***/
import './burgerIcon.sass';


const BurgerIcon = (props) => {
  const barColor = props.barColor;
  const barStyle = {backgroundColor: barColor};
  return(
      <div className={props.buttonActive ? "burgerIcon burgerIcon--is-active" : "burgerIcon"}>
        <div className="burgerIcon__line" style={barStyle}></div>
        <div className="burgerIcon__line" style={barStyle}></div>
        <div className="burgerIcon__line" style={barStyle}></div>
      </div>
  );
}

export default BurgerIcon;
