import React from 'react';

import {ProfilePicture} from '../../atoms'

const UserSection = (props) => {
  return(
    <div className="lb__user">
      <ProfilePicture name={props.name} path={props.path} />
      <div className="lb__user-sum">
        <span className="lb__user-sumName">Summe</span>
        {props.sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
      </div>
    </div>
  );
}

export default UserSection;
