import React from 'react';

import './checkBox.sass'

class CheckBox extends React.Component {

  render(){
    return(
      <div className="cb__container">
        <input type="checkbox" className="cb__box" id={this.props.name} name={this.props.name} checked={this.props.checked} onChange={() => this.props.changeHandler(this.props.idx)}/>
        <label htmlFor={this.props.name} className="cb__label">
          {this.props.name}
        </label>
      </div>
    )
  }
}

export default CheckBox
