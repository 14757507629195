import React from 'react';

import './reloadbutton.sass'

const ReloadButton = (props) => {
  return(
    <div className={`reloadbutton`} onClick = {() => props.action()} />
  )
}

export default ReloadButton
