import React from 'react';

import './logo.sass'

import logo from '../../../img/monsy.svg'


const FullLogo = () => {
  return(
    <div className="fullLogo__container">
      <span className="fullLogo__text">m</span>
      <img className="fullLogo__img" src={logo} alt="monsy-Logo" />
      <span className="fullLogo__text">nsy</span>
    </div>
  )
}

export default FullLogo;
