import gql from 'graphql-tag';
/*** Update-Query for local store
 * Updating intentName
 ***/
export const updateFilters = gql`
  mutation updateFilters($filteredByUsers: [String], $filteredByCategory: [String], $filteredByDateLower: String, $filteredByDateUpper: String, $filteredByAmountLower: Integer, $filteredByAmountUpper: Integer) {
    updateFilters(filteredByUsers: $filteredByUsers, filteredByCategory: $filteredByCategory, filteredByDateLower: $filteredByDateLower, filteredByDateUpper: $filteredByDateUpper, filteredByAmountLower: $filteredByAmountLower, filteredByAmountUpper: $filteredByAmountUpper) @client {
      filteredByUsers
      filteredByCategory
      filteredByDateLower
      filteredByDateUpper
      filteredByAmountLower
      filteredByAmountUpper
    }
  }
`;
