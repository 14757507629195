import React from 'react';

import './listItem.sass'

import {CloseIcon, ProfilePicture} from '../../atoms'

import edit from '../../../img/edit-icon.svg'

class ListItem extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      active: false,
    }
  }


  render(){;
    return(
      <li className="li__outer_container">
        <div className={this.state.active ? "li__container li__container--is-active" : "li__container"} onClick={() => this.setState({active:!this.state.active
        })}>
          <div className="li__date">
            {new Date(this.props.date).toLocaleString('de-DE', {"day": "2-digit", "month": "2-digit"})}
            <div className="li__date-year">{new Date(this.props.date).toLocaleString('de-DE', {"year": "numeric"})}</div>
          </div>
          <div className="li__avatar">
            <ProfilePicture path={this.props.user.img_profile} name={this.props.user.name} />
          </div>
          <div className="li__descr">
            {this.props.description}
          </div>
          <div className="li__cat">
            {this.props.category}
          </div>
          <div className="li__amount">
            {this.props.amount.toLocaleString('de-DE', { style: 'currency', currency: this.props.currency })}
          </div>
        </div>
        <div className="li__settings">
          <div className="li__settings-item" onClick={() => this.props.handleEdit({user: this.props.user, date: this.props.date, category: this.props.category, description: this.props.description, amount: this.props.amount})}>
            <img className="svg_edit" src={edit} alt="edit"/>
          </div>
          <div className="li__settings-item">
            <CloseIcon color="bg_white" />
          </div>
        </div>
    </li>
    )
  }
};

export default ListItem;
