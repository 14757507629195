import React from 'react';

import './textinput.sass'

import {CloseIcon} from '../'
import {withOutsideClick} from '../../HOCs'

class Autosuggest extends React.Component {

  handleClickOutside = () => this.props.closeElement()

  render(){
  return(
    <ul className="autosuggest__container">
      {this.props.filteredSuggestions.map((item, idx) => {
        let className = "";
        if(idx === this.props.activeSuggestion){
          className = "--is-active";
        }
        return (
          <li className={"autosuggest__suggestion" + className} key={item} onClick={this.props.choseSuggestion}>{item}</li>
        )
      })}
    </ul>
  )}
}

const BlurredAutosuggest = withOutsideClick(Autosuggest)


class TextInput extends React.Component {

  state = {
    value: this.props.value,
    showSuggestions: false,
    activeSuggestion: 0,
    filteredSuggestions: []

  }

  handleChange = async (e) => {
    const {suggestions} = this.props
    const value = e.target.value
    const filteredSuggestions = suggestions.filter(
      sug => !sug.toLowerCase().indexOf(value.toLowerCase())
    );
    await this.setState({value, filteredSuggestions, showSuggestions: true});

    this.props.onInputChanged(value)
  }

  resetField = async () => {
    await this.setState({value: ""});
    this.props.onInputChanged(this.state.value)
  }

  choseSuggestion = (e) => {
    const value = e.currentTarget.innerText
    this.setState({value, showSuggestions: false, filteredSuggestions: [], activeSuggestion: 0})
    this.props.onInputChanged(value)
  }

  handleKeyDown = e => {
    let {activeSuggestion, filteredSuggestions} = this.state

    // enter - set currentTarget
    if(e.keyCode === 13){
      this.setState({value: filteredSuggestions[activeSuggestion], showSuggestions: false, activeSuggestion: 0})
    }
    // arrow up -> decrement activeSuggestion
    else if(e.keyCode === 38){
      if(activeSuggestion===0){
        return;
      }
      else{
        this.setState({activeSuggestion: activeSuggestion-1})
      }
    }
    // arrow down -> increment activeSuggestion
    else if(e.keyCode === 40){
      if(activeSuggestion-1 === filteredSuggestions.length){
        return;
      }
      else{
        this.setState({activeSuggestion: activeSuggestion +1});
      }
    }
  }

  closeSuggestion = () => this.setState({showSuggestions: false})

  render () {
    const placeholder = this.props.placeholder ? this.props.placeholder : "Bitte eingeben..."
    const label = this.props.label ? this.props.label : "Texteingabe"
    const {showSuggestions, activeSuggestion, filteredSuggestions} = this.state
    return(
      <React.Fragment>
        <label className="addscreen__label">{label}</label>
        <div className="addscreen__input__container autosuggest__input">
          <input className="addscreen__input--field" type="text" required value={this.state.value} placeholder={placeholder} onChange={this.handleChange} onKeyDown={this.handleKeyDown}/>
          {showSuggestions && <BlurredAutosuggest activeSuggestion={activeSuggestion} filteredSuggestions={filteredSuggestions} choseSuggestion={this.choseSuggestion} closeElement={this.closeSuggestion}/>}
          <div className="addscreen__remove">
            <CloseIcon color="bg_grey" closeElement={this.resetField} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default TextInput;
