export default {
  filters: {
    __typename: 'Filters',
    sortBy: 'date',
    sortReverse: false,
    filteredByUsers: [],
    filteredByCategory: [],
    filteredByDateLower: null,
    filteredByDateUpper: null,
    filteredByAmountLower: null,
    filteredByAmountUpper: null,
  }
}
