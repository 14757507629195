import React from 'react';

import Calendar from './Calendar';
import './datepicker.sass';
import {isDate, getDateISO, ISOToGerman} from './calendarHelper';
import {CloseIcon} from '../../atoms'
import {withOutsideClick} from '../../HOCs'

const BlurredCalendar = withOutsideClick(Calendar)

class DatePicker extends React.Component{
  state = {
    date: null,
    calendarOpen: false
  }

  toggleCalendar = () => this.setState({calendarOpen: !this.state.calendarOpen});

  handleChange = e => e.preventDefault();

  handleDateChange = date => {
    const {onDateChanged} = this.props;
    const currentDate = this.state.date;
    const newDate = date ? getDateISO(date) : null;
    currentDate !== newDate && this.setState({date: newDate, calendarOpen: false}, ()=>{
      typeof onDateChanged === 'function' && onDateChanged(this.state.date)
    });
  }

  componentDidMount() {
    const {value: date} = this.props;
    const newDate = date && new Date(date);
    isDate(newDate) && this.setState({date: getDateISO(newDate)})
  }

  componentDidUpdate(prevProps){
    const {value: date} = this.props;
    const {value: prevDate} = prevProps;
    const dateISO = getDateISO(new Date(date));
    const prevDateISO = getDateISO(new Date(prevDate));
    dateISO !== prevDateISO && this.setState({date: dateISO});
  }

  clearInput = () => {
    this.setState({date: null});
    this.handleDateChange(undefined)
  }

  closeCalendar = () => this.setState({calendarOpen: false});

  render(){
    const {label} = this.props
    const {calendarOpen} = this.state;
    const date = (this.state.date === "1970-01-01") ? undefined : this.state.date;
    return(
      <div className="datepicker__container">
        <div className="datepicker__form">
          <label className="datepicker__label">
            {label || "Wähle ein Datum aus"}
          </label>
          <div className = "datepicker__input">
            <input className="datepicker__input--field" type="text" value={date ? ISOToGerman(date): ""} onChange={this.handleChange} readOnly="readonly" placeholder = "DD.MM.JJJJ" onClick={this.toggleCalendar}/>
            <div className="datepicker__input--close">
              <CloseIcon color="bg_grey" closeElement={this.clearInput}/>
            </div>
          </div>
        </div>
        {this.props.error && <div className="datepicker__error">{this.props.error}</div>}

        {calendarOpen &&
          <div className="calendarpick__container">
            <div className="calendarpick__header">
              <h2>Wähle ein Datum:</h2>
              <div className="calendarpick__close">
                <CloseIcon color="bg_black" closeElement={this.closeCalendar} />
              </div>
            </div>
            <BlurredCalendar date={date && new Date(date)} onDateChanged={this.handleDateChange} closeElement={this.closeCalendar}/>
          </div>}
      </div>
    )
  }
}

export default DatePicker;
