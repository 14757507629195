import React from 'react';

import CloseIcon from '../CloseIcon'
import './currencyinput.sass'

class CurrencyInput extends React.Component{

  state = {
    value: this.props.amount
  }

  convertToFloat = (value) => {
    const intVal = parseInt(value,10)/Math.pow(10,2)
    return intVal.toFixed(2)
  }

  handleChange = async e => {
    const cleanStr = e.target.value.replace(/,/, '')
    const value = this.convertToFloat(cleanStr)
    const displayValue = value.replace(/\./, ',')
    await this.setState({value: displayValue});
    this.props.onInputChanged(parseFloat(value))
  }

  resetField = async () => {
    await this.setState({value: 0.0})
    this.props.onInputChanged(this.state.value)
  }

  render(){
    console.log('this.state (currencyinput)', this.state);
    return(
      <React.Fragment>
        <label className="addscreen__label">{this.props.label}</label>
        <div className="addscreen__input__container">
          <input className="addscreen__input--field" type="tel" lang="de-150" value={this.state.value} required onChange={this.handleChange}/>
          <div className="addscreen__remove">
            <CloseIcon color="bg_grey" closeElement={this.resetField} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CurrencyInput
