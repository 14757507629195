import React from 'react';

import './addscreen.sass'

import {CloseIcon, CurrencyInput, TextInput, TwoStepButton} from '../../atoms'
import {DatePicker, UserSelection} from '../../molecules'

import {getAllTypes} from '../../../utils/dataManagement'

class AddScreen extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      submissionComplete: false,
      submissionStatus: ["Betrag", "Ausgabe", "NutzerIn", "Kategorie"],
      amount: 0.0,
      date: new Date(),
      user: undefined,
      type: "",
      category: ""
    }
  }


  componentDidMount() {
    this.initializeState();
  }

  initializeState = () => {

    if(this.props.item){
      console.log('this.props.item', this.props.item);
      const {amount, date, user, category} = this.props.item
      const type = this.props.item.description
      const submissionStatus = []
      const submissionComplete = true
      this.setState({amount, date, user, category, type, submissionStatus, submissionComplete})
    }
  }

  updateAmount = async(amount) => {
    await this.setState({amount});
    this.checkForCompleteForm()
  }

  updateDate = async(date) => {
    await this.setState({date})
    this.checkForCompleteForm()
  }

  updateUser = async(user) => {
    await this.setState({user})
    this.checkForCompleteForm()
  }

  updateType = async(type) => {
    await this.setState({type})
    this.checkForCompleteForm()
  }

  updateCategory = async(category) => {
    await this.setState({category})
    this.checkForCompleteForm()
  }

  renderIncomplete = () => {
    const {submissionStatus} = this.state
    return "Es fehlt: " + submissionStatus.join(", ")
  }

  filterSubmissionStatus = (submissionStatus, toFilter) => {
    submissionStatus = submissionStatus.filter(function(v, i, a){
      return v !== toFilter
    })
    return submissionStatus
  }

  checkForCompleteForm = () => {
    let checkSum = 0
    let {amount, date, user, type, category, submissionStatus, submissionComplete} = this.state
    if(amount !== 0){
      checkSum += 1
      submissionStatus = this.filterSubmissionStatus(submissionStatus, "Betrag")
    }else{
      submissionStatus.push("Betrag")
    }
    if(user){
      checkSum += 1;
      submissionStatus = this.filterSubmissionStatus(submissionStatus, "NutzerIn")
    }else{
      submissionStatus.push("NutzerIn")
    }
    if(type){
      checkSum += 1;
      submissionStatus = this.filterSubmissionStatus(submissionStatus, "Ausgabe")
    }else{
      submissionStatus.push("Ausgabe")
    }
    if(category){
      checkSum += 1;
      submissionStatus = this.filterSubmissionStatus(submissionStatus, "Kategorie")
    }else{
      submissionStatus.push("Kategorie")
    }
    if(date){
      checkSum += 1
      submissionStatus = this.filterSubmissionStatus(submissionStatus, "Datum")
    }else{
      submissionStatus.push("Datum")
    }
    if(checkSum === 5){
      submissionComplete = true
    }
    submissionStatus = [...new Set(submissionStatus)]
    this.setState({submissionStatus, submissionComplete})
  }

  submit = (e) => {
    e.preventDefault();
    if(!this.state.submissionComplete) return
    const newData = [...this.props.data, {amount: this.state.amount, currency: "EUR", user: this.state.user, category: this.state.category, description: this.state.type, date: this.state.date.toISOString(), id: this.props.data.length + 1}]
    this.props.handleChange(newData)
  }

  render(){
    console.log('this.state (AddScreen)', this.state);
    const amount = this.state.amount
    console.log('amount', amount);
    return(
      <section className="addscreen__container">
        <div className="addscreen__header">
          <h1>Neuer Eintrag</h1>
          <div className = "addscreen__close">
            <CloseIcon closeElement={this.props.close} color="bg_black"/>
          </div>
        </div>
        <form className="addscreen__form" onSubmit={this.submit}>
          <div className="addscreen__input">
            <CurrencyInput label="Betrag" amount={amount} onInputChanged={this.updateAmount}/>
          </div>
          <div className="addscreen__input">
            <TextInput value={this.state.type} placeholder="Bezeichnung der Ausgabe" label="Ausgabe" onInputChanged={this.updateType} suggestions={getAllTypes(this.props.data)}/>
          </div>
          <div className="addscreen__input">
            <UserSelection user label="Nutzer:" selectedItem={this.state.user ? this.state.user : undefined} data={this.props.data} onInputChanged={this.updateUser}/>
          </div>
          <div className="addscreen__input">
            <UserSelection label="Kategorie" data={this.props.data} onInputChanged={this.updateCategory}/>
          </div>
          <div className="addscreen__input">
            <DatePicker value={this.state.date} label="Datum" onDateChanged={this.updateDate}/>
          </div>
          <TwoStepButton buttonActive={this.state.submissionComplete} textActive="Änderungen übernehmen" textInactive={this.renderIncomplete()} clickHandler={this.submit}>
          </TwoStepButton>
        </form>

      </section>
    )
  }
}

export default AddScreen
