import React from 'react';

import './checkBox.sass'

import ProfilePicture from '../ProfilePicture'

class CheckBoxUser extends React.Component {

  render(){
    return(
      <div className="cb__container">
        <input type="checkbox" className="cb__box" id={this.props.name} name={this.props.name} checked={this.props.checked} onChange={() => this.props.changeHandler(this.props.idx)}/>
        <label htmlFor={this.props.name} className="cb__label cb__label--user">
          <div className="cb__label-imgBox">
            <ProfilePicture name={this.props.name} path={this.props.path} />
          </div>
          {this.props.name}
        </label>
      </div>
    )
  }
}

export default CheckBoxUser
