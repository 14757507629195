import React, { Component } from 'react';
import {ExpenseList, Menu} from './modules'

import './index.sass'

class App extends Component {

  render() {
    return (
      <div className="main">

        <ExpenseList />
        <Menu />
      </div>
    );
  }
};

export default App;
