import React from 'react';

import './checkButton.sass'

const CheckButton = (props) => {
  return(
    <div className={`checkbutton ${props.active ? "checkbutton--is-active" : ""}`} onClick={props.action} title={props.title}>
      <div className={`checkbutton__inner checkbutton__inner--${props.color} ${props.active ? "checkbutton__inner--is-active" : ""}`} title={props.title}></div>
    </div>
  )
}

export default CheckButton
