import React from 'react';

import './twosidedrange.sass'

class TwoSidedRange extends React.Component {
  constructor(props){
    super(props)
    this.state={
      x: [0, 0],
      width: 0,
      dragging: false,
      active: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentMin !== this.props.currentMin || prevProps.currentMax !== this.props.currentMax){
      this.calculateXs(this.state.width)
    }
  }

  refCallback = element => {
    if (element) {
      const size = element.getBoundingClientRect();
      this.calculateXs(size.width)
    }
  };

  calculateXs = (width) => {
    if(this.props.currentMin === null || this.props.currentMax === null){
      this.setState({width: width, x: [this.calculatePos(this.props.minValue, 0, width), this.calculatePos(this.props.maxValue, 1, width)]});
    } else{
      this.setState({width: width, x: [this.calculatePos(this.props.currentMin, 0, width), this.calculatePos(this.props.currentMax, 1, width)]});
    }
  }

  calculatePos = (value, idx, width) => {
    const pos = Math.trunc(value/this.props.maxValue*width)
    return pos
  }

  calculateVal = (idx) => {
    let x
    if (this.state.width > 0){
      x = this.state.x[idx]/(this.state.width)*this.props.maxValue;
      x = Math.round(x)
    }
    else{
      x = "NaN"
    }
    return x
  }

  onStart = (e, idx) => {
    this.setState({dragging: true, active: idx});
  }

  onEnd = () => {
    this.setState({dragging: false, active: 0});
    this.props.setValue(this.calculateVal(0), this.calculateVal(1))
  }

  onMove = (e) => {
    if(this.state.dragging){
      const idx = this.state.active
      const x = this.state.x
      const posX = x[idx]
      x[idx] = posX + e.movementX
      if(x[idx] < this.calculatePos(this.props.minValue, 0, this.state.width)){
        x[idx] = this.calculatePos(this.props.minValue, 0, this.state.width)
      }
      else if (x[idx] > this.state.width) {
        x[idx] = this.state.width
      }
      if(idx === 0){
        if(x[idx] > 0){
          x[idx] = x[0]
        }
      }else{
        if(x[idx] < x[0]){
          x[idx] = x[0]
        }
      }
      this.setState({x})
    }
  }

  onMouseMove = (e) => {
    this.onMove(e);
    e.preventDefault();
  }

  onMouseDown = (e,idx) => {
    if(e.button !== 0) return;
    this.onStart(e,idx);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    e.preventDefault();
  }

  onMouseUp = (e) => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.onEnd();
    e.preventDefault();
  }

  onTouchStart(e, idx) {
      this.onStart(e.touches[0], idx);
      document.addEventListener('touchmove', this.onTouchMove, {passive: false});
      document.addEventListener('touchend', this.onTouchEnd, {passive: false});
      e.preventDefault();
  }

  onTouchMove(e) {
      this.onMove(e.touches[0]);
      e.preventDefault();
  }

  onTouchEnd(e) {
      document.removeEventListener('touchmove', this.onTouchMove);
      document.removeEventListener('touchend', this.onTouchEnd);
      this.onEnd();
      e.preventDefault();
  }

  render(){
    return(
      <div className="tsr__container">
        <div className = "tsr__rail__container">
          <div className="tsr__rail" ref={this.refCallback}></div>
          <div className="tsr__rail tsr__rail__selected" style={{left: this.state.x[0], width: (this.state.x[1] - this.state.x[0])}}></div>
          <div className = "tsr__handle" style={{left: this.state.x[0]}} onMouseDown={(e) => this.onMouseDown(e, 0)} onTouchStart={(e) => this.onTouchStart(e, 0)}>{this.calculateVal(0)} €</div>
          <div className = "tsr__handle" style={{left: this.state.x[1]}} onMouseDown={(e) => this.onMouseDown(e, 1)} onTouchStart={(e) => this.onTouchStart(e, 1)}>{this.calculateVal(1)} €</div>
        </div>
      </div>
    )
  }
};

export default TwoSidedRange;
