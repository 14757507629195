import React from 'react';

import {CSSTransition} from 'react-transition-group'

import './menu.sass'
import {MenuButton, FullLogo, CheckForm, RangeFilter, DateFilter} from '../../molecules'

import {data} from '../../../data/dummy.js'

class Menu extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isActive: false
    }
  }
  openMenu = () =>{
    this.setState({isActive:!this.state.isActive});
  }

  // Must be exchanged for actual data-sorting logic
  uniqueFiltering = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  getAllCategories = () => {
    const allCategories = [...new Set(data.map(item => item.category))]
    return allCategories.filter(this.uniqueFiltering)
  }

  // Must be exchanged for actual data-sorting logic
  getAllUsers = () => {
    const allUsers = [...new Set(data.map(item => item.user.name))];
    return allUsers.filter(this.uniqueFiltering)
  }

  // Must be exchanged for actual data-sorting logic
  getUserData = (users) => {
    let userProfiles = []
    users.forEach((user) => {
      const userData = data.find((el) => el.user.name === user)
      userProfiles.push(userData.user)
    })
    return userProfiles
  }

  getMinAndMax = () => {
    const max = Math.max.apply(Math, data.map(function(o) { return o.amount; }))
    const min = Math.min.apply(Math, data.map(function(o) { return o.amount; }))
    return [Math.trunc(min), Math.trunc(max)+1]
  }

  render(){
    const users = this.getUserData(this.getAllUsers())
    const categories = this.getAllCategories()
    const minMax = this.getMinAndMax()
    return(
      <React.Fragment>
        <MenuButton clickHandler={this.openMenu}/>
        <CSSTransition in={this.state.isActive} timeout={500}  classNames="menu__container" unmountOnExit>
          <menu className="menu__container">
            <CheckForm items={users} title="Nach Personen filtern:" type="img" />
            <CheckForm items={categories} title="Nach Kategorien filtern:" type="reg" />
            <RangeFilter minValue={minMax[0]} maxValue={minMax[1]}/>
            <DateFilter />
            <FullLogo />

          </menu>
      </CSSTransition>
      </React.Fragment>
    );
  }
}

export default Menu;
